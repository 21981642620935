import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import CustomLink from './custom-link'

export const useStyles = makeStyles(theme => ({
  root: () => ({
    padding: '70px 0 105px',
    background: theme.palette.blue.light100,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 0 40px',
      '& h3': {
        textAlign: 'center',
        padding: '0 40px'
      }
    }
  }),
  list: {
    listStyleType: 'none',
    paddingLeft: '20px',
    marginBottom: '0px',
    marginTop: '16px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px'
    }
  },
  listItem: {
    position: 'relative',
    zIndex: '10',
    color: '#000',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '12px',
    fontFamily: 'Nunito Sans,sans-serif',
    fontWeight: '600',
    listStyleType: 'none',
    '&:before': {
      content: '"1"',
      height: '24px',
      width: '24px',
      backgroundColor: theme.palette.brand.main,
      color: theme.palette.common.white,
      borderRadius: '12px',
      position: 'absolute',
      left: '-22px',
      top: '3.5px',
      zIndex: '-1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '&:nth-child(2)': {
      '&:before': {
        content: '"2"'
      }
    },
    '&:nth-child(3)': {
      '&:before': {
        content: '"3"'
      }
    }
  }
}))

const GetStarted = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      id="get-started"
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box maxWidth="510px">
              <Typography variant="h3" color="secondary" gutterBottom>
                Supercharge Your Coaching Business in 2024
              </Typography>
              {!isMobile && (
                <Button
                  variant="contained"
                  color="secondary"
                  component={CustomLink}
                  href="https://my.pga.com/pga-coach?utm_campaign=coachlp&utm_source=start"
                  target="_blank"
                  rel="noreferrer"
                >
                  Launch PGA Coach
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h5" gutterBottom>
              Get Started in 3 Easy Steps
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                Login with your PGA Member account
              </li>
              <li className={classes.listItem}>Follow the onboarding steps</li>
              <li className={classes.listItem}>Share your coach profile</li>
            </ul>
            {isMobile && (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                component={CustomLink}
                href="https://my.pga.com/pga-coach?utm_campaign=coachlp&utm_source=start"
                target="_blank"
                rel="noreferrer"
              >
                Launch PGA Coach
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default GetStarted
