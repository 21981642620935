import React from 'react'
import { Box, Typography } from '@material-ui/core'
import AppStoreBadge from '../images/store-badges/Download_on_the_App_Store_Badge.png'
import GooglePlayBadge from '../images/store-badges/google-play-badge.png'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px 0 48px',
    backgroundColor: theme.palette.offwhite.main,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 40px 40px'
    }
  },
  lighterText: {
    color: theme.palette.primary.contrastText,
    opacity: '0.8'
  },
  badgeContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '32px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& a:first-child': {
        marginBottom: '16px',
        display: 'block'
      }
    }
  },
  badge: {
    width: 'auto',
    height: '49px',
    margin: '0 12px',
    [theme.breakpoints.down('xs')]: {
      height: '44px'
    }
  }
}))

export default function DownloadMyPGAsection() {
  const classes = useStyles()

  return (
    <Box textAlign="center" className={classes.root} id="download">
      <Typography
        variant="h3"
        color="secondary"
        style={{ marginBottom: '16px' }}
      >
        Download the PGA Coach App
      </Typography>
      <Typography variant="body1">
        Stay connected with your students and manage your business.
      </Typography>
      <Box className={classes.badgeContainer}>
        <a
          href="https://apps.apple.com/us/app/pga-coach/id1448721089"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={AppStoreBadge}
            alt="MyPGA app on App Store"
            className={classes.badge}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.pga.pgacoach&hl=en_US&gl=US"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={GooglePlayBadge}
            alt="MyPGA app on Google Play"
            className={classes.badge}
          />
        </a>
      </Box>
    </Box>
  )
}
