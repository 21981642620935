import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Img from 'gatsby-image'
import Chip from '@material-ui/core/Chip'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CustomLink from './custom-link'
import { IconButtonCtaArrow } from './icons'

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px 0 48px',
    textAlign: 'center',
    '& h3': {
      marginBottom: '60px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '24px'
      }
    }
  },
  columnInner: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '100px',
      textAlign: 'left'
    }
  },
  columnInnerReversed: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '80px',
      textAlign: 'left'
    }
  },
  ctaButton: {
    paddingLeft: '0',
    paddingRight: '0',
    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  },
  featureItem: {
    paddingBottom: '30px',
    paddingTop: '30px',
    '&:nth-child(even)': {
      '& .gatsby-image-wrapper': {
        transform: 'translateX(-55px)',
        [theme.breakpoints.down('sm')]: {
          transform: 'translateX(0)'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '16px',
      paddingTop: '16px'
    }
  },
  columnImage: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}))

export default function ProductPillars({ images }) {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  return (
    <Box className={classes.root}>
      <Typography variant="h3" color="secondary">
        The Right Tools for the Modern Coach
      </Typography>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          alignItems="center"
          className={classes.featureItem}
          direction={isMobile ? 'column' : 'row-reverse'}
        >
          <Grid item md={6} xs={12} className={classes.columnImage}>
            {isMobile ? (
              <Img fixed={images.MarketingImageMobile.childImageSharp.fixed} />
            ) : (
              <Img fixed={images.MarketingImage.childImageSharp.fixed} />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Box className={classes.columnInner}>
              <Typography
                variant="h5"
                color="secondary"
                style={{ marginBottom: '16px' }}
              >
                Marketing & SEO Powered by PGA of America
              </Typography>
              <Typography variant="body1">
                Boost your brand with a dedicated PGA webpage and integrated
                booking, connecting golfers to your lessons, programs and
                events.
              </Typography>
              <Button
                variant="text"
                color="secondary"
                style={{ marginTop: '16px' }}
                endIcon={<IconButtonCtaArrow />}
                className={classes.ctaButton}
                component={CustomLink}
                href="https://my.pga.com/pga-coach/profile?utm_campaign=coachlp"
                target="_blank"
                rel="noreferrer"
              >
                Create Your Profile
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          alignItems="center"
          className={classes.featureItem}
        >
          <Grid item md={6} xs={12} className={classes.columnImage}>
            {isMobile ? (
              <Img
                fixed={images.ModernToolsImageMobile.childImageSharp.fixed}
              />
            ) : (
              <Img fixed={images.ModernToolsImage.childImageSharp.fixed} />
            )}
          </Grid>
          <Grid item md={6} xs={12} className={classes.columnImage}>
            <Box className={classes.columnInnerReversed}>
              <Typography
                variant="h5"
                color="secondary"
                style={{ marginBottom: '16px' }}
              >
                Simplify Your Business Tools
              </Typography>
              <Typography variant="body1">
                Streamline the behind-the-scenes with app and web-based tools to
                handle scheduling, programs and events, payments, messaging and
                more.
              </Typography>
              <Button
                variant="text"
                color="secondary"
                style={{ marginTop: '16px' }}
                endIcon={<IconButtonCtaArrow />}
                className={classes.ctaButton}
                component={CustomLink}
                href="https://my.pga.com/pga-coach?utm_campaign=coachlp&utm_source=feat"
                target="_blank"
                rel="noreferrer"
              >
                Launch Business Tools
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          alignItems="center"
          className={classes.featureItem}
          direction={isMobile ? 'column' : 'row-reverse'}
        >
          <Grid item md={6} xs={12} className={classes.columnImage}>
            {isMobile ? (
              <Img
                fixed={images.CoachTrainingImageMobile.childImageSharp.fixed}
              />
            ) : (
              <Img fixed={images.CoachTrainingImage.childImageSharp.fixed} />
            )}
          </Grid>
          <Grid item md={6}>
            <Box className={classes.columnInner}>
              <Typography
                variant="h5"
                color="secondary"
                style={{ marginBottom: '16px' }}
              >
                Coach Training & Development
              </Typography>
              <Typography variant="body1">
                Elevate your skills and redefine your coaching journey with
                resources from the PGA like Modern Coaching training, ADM
                certification and age-appropriate activity plans.
              </Typography>
              <Box display="flex">
                <Button
                  variant="text"
                  color="secondary"
                  style={{ marginTop: '16px' }}
                  endIcon={<IconButtonCtaArrow />}
                  className={classes.ctaButton}
                  component={CustomLink}
                  href="/training"
                >
                  Learn about available courses
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          alignItems="center"
          className={classes.featureItem}
        >
          <Grid item md={6} xs={12} className={classes.columnImage}>
            {isMobile ? (
              <Img
                fixed={images.VideoSharingImageMobile.childImageSharp.fixed}
              />
            ) : (
              <Img fixed={images.VideoSharingImage.childImageSharp.fixed} />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Box className={classes.columnInnerReversed}>
              <Typography
                variant="h5"
                color="secondary"
                style={{ marginBottom: '16px' }}
              >
                <span style={{ marginRight: '8px' }}>
                  {' '}
                  Video Sharing & Analysis
                </span>
                <Chip
                  label="Coming Soon"
                  variant="outlined"
                  color="primary"
                  size="small"
                />
              </Typography>
              <Typography variant="body1">
                Get access to record and analyze videos to seamlessly share with
                your students on the MyPGA app.
              </Typography>
              <Box display="flex">
                <Button
                  variant="text"
                  color="secondary"
                  style={{ marginTop: '16px' }}
                  endIcon={<IconButtonCtaArrow />}
                  className={classes.ctaButton}
                  component={CustomLink}
                  href="https://sprw.io/stt-ePm2kZvTDvjnLV45wvWSGp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Request early access
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          alignItems="center"
          className={classes.featureItem}
          direction={isMobile ? 'column' : 'row-reverse'}
        >
          <Grid item md={6} xs={12} className={classes.columnImage}>
            {isMobile ? (
              <Img fixed={images.StudentAppImageMobile.childImageSharp.fixed} />
            ) : (
              <Img fixed={images.StudentAppImage.childImageSharp.fixed} />
            )}
          </Grid>
          <Grid item md={6}>
            <Box className={classes.columnInner}>
              <Typography
                variant="h5"
                color="secondary"
                style={{ marginBottom: '16px' }}
              >
                Companion App for Students
              </Typography>
              <Typography variant="body1">
                Empower and connect with your students on the MyPGA app for
                seamless lesson booking, messaging, video sharing and more.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
