import React from 'react'
import { LayoutV2 } from '../components/common'
import Hero from '../components/hero-image'
import Stats from '../components/stats'
import GetStarted from '../components/get-started'
import FAQ from '../components/faq'
import DownloadMyPGAsection from '../components/download-mypga-section'
import Testimonials from '../components/testimonials'
import ProductPillars from '../components/product-pillars'
import { graphql } from 'gatsby'
import { JssProvider } from 'react-jss'
import { withSessionReplay } from '../utils/datadog'

import { createGenerateClassName } from '@material-ui/core/styles'

const IndexNewPage = props => {
  const classPrefix = 'index-new-page'
  const { heroImage, heroImageMobile, Topography, Testimonial } = props.data
  const generateClassName = createGenerateClassName({
    productionPrefix: classPrefix
  })
  return (
    <JssProvider generateClassName={generateClassName}>
      <LayoutV2 title="PGA.Coach New">
        <Hero
          image={heroImage}
          mobileImage={heroImageMobile}
          title="Powering the Game’s Best Coaches"
          description="With a better work-life balance that allows you to focus on
coaching, PGA Coach has the tools to help you grow and manage your
business, and the expert training designed to help you better
engage, inspire and retain golfers."
          actions
        />
        <ProductPillars images={props.data} />
        <Stats image={Topography} />
        <GetStarted />
        <Testimonials image={Testimonial} />
        <FAQ />
        <DownloadMyPGAsection />
      </LayoutV2>
    </JssProvider>
  )
}

export const query = graphql`
  query SearchQuery1 {
    heroImage: file(relativePath: { eq: "hero-image.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroImageMobile: file(relativePath: { eq: "hero-image-mobile.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Topography: file(relativePath: { eq: "Topography.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Testimonial: file(relativePath: { eq: "coach-testimonial-quote-bg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MarketingImage: file(relativePath: { eq: "Marketing image.png" }) {
      childImageSharp {
        fixed(width: 513, height: 244) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    MarketingImageMobile: file(relativePath: { eq: "Marketing image.png" }) {
      childImageSharp {
        fixed(width: 342, height: 156) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ModernToolsImage: file(relativePath: { eq: "Modern Tools.png" }) {
      childImageSharp {
        fixed(width: 472, height: 319) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ModernToolsImageMobile: file(relativePath: { eq: "Modern Tools.png" }) {
      childImageSharp {
        fixed(width: 329, height: 219) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    CoachTrainingImage: file(relativePath: { eq: "Coach Training.png" }) {
      childImageSharp {
        fixed(width: 538, height: 329) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    CoachTrainingImageMobile: file(relativePath: { eq: "Coach Training.png" }) {
      childImageSharp {
        fixed(width: 346, height: 210) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    VideoSharingImage: file(
      relativePath: { eq: "Video Sharing Analysis.png" }
    ) {
      childImageSharp {
        fixed(width: 376, height: 376) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    VideoSharingImageMobile: file(
      relativePath: { eq: "Video Sharing Analysis.png" }
    ) {
      childImageSharp {
        fixed(width: 307, height: 295) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    StudentAppImage: file(relativePath: { eq: "Student App.png" }) {
      childImageSharp {
        fixed(width: 509, height: 385) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    StudentAppImageMobile: file(relativePath: { eq: "Student App.png" }) {
      childImageSharp {
        fixed(width: 329, height: 252) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default withSessionReplay(IndexNewPage)
