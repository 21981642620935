import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { IconArrow } from './icons'

export const useStyles = makeStyles(theme => ({
  root: () => ({
    color: theme.palette.primary.contrastText,
    background: `rgba(0,35,75,.7)`
  }),
  button: {
    width: '3.0625rem',
    height: '3.0625rem',
    display: 'flex',
    padding: 0,
    minWidth: 'initial',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    color: '#3268A7',
    position: 'absolute',
    bottom: '60px',
    left: '3.0625rem',
    border: 'none',
    zIndex: '100',
    '&:hover': {
      color: '#F7F7F7',
      backgroundColor: '#001529'
    },
    '&:focus': {
      border: '3px solid white',
      outline: '3px solid black'
    }
  },
  prevButton: {
    transform: 'rotate(180deg)',
    backgroundColor: '#F7F7F7',
    left: '0'
  },
  slide: {
    height: '570px',
    paddingTop: '100px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '75px'
    }
  },
  title: {
    marginTop: '30px',
    marginBottom: '40px'
  }
}))

const PrevButton = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button
      className={`${classes.button} ${classes.prevButton}`}
      onClick={onClick}
    >
      <IconArrow />
    </button>
  )
}

const NextButton = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button className={classes.button} onClick={onClick}>
      <IconArrow />
    </button>
  )
}

const Testimonials = ({ image, className }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />
  }
  const testimonials = [
    {
      author: 'Kathy Cassese',
      title: 'PGA, LPGA',
      quote:
        'My PGA Coach Profile has significantly boosted my coaching career by generating valuable leads.'
    },
    {
      author: 'Patrick Oropallo',
      title: 'PGA',
      quote:
        'I used to spend so much time booking lessons, but not anymore. I just send everyone my link and collect payment online.'
    },
    {
      author: 'Eric Thielsen',
      title: 'PGA',
      quote:
        'PGA Coach has been a game-changer. I’m not just coaching golf; I’ve found that sweet spot of work/life balance I’ve been chasing my entire career.'
    },
    {
      author: 'Chris Casto',
      title: 'PGA',
      quote: `I can't imagine living without the PGA Coach app - I use it for everything from scheduling and messaging to leads and group events.`
    },
    {
      author: 'Ryan Young',
      title: 'PGA',
      quote:
        'The Modern PGA Coach Series helped me figure out how to be a coach in the 21st century.'
    },
    {
      author: 'Chance Scheffing',
      title: 'PGA',
      quote:
        'This has transformed the way I coach, schedule and stay connected with my 300+ students.'
    }
  ]
  return (
    <BackgroundImage
      Tag="section"
      fluid={image.childImageSharp.fluid}
      className={className}
    >
      <Box className={classes.root} display="flex" flexDirection="column">
        <Container maxWidth="xl">
          <Box maxWidth="740px" px={isMobile ? 3 : 0}>
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div className={classes.slide} key={index}>
                  <img
                    src="https://assets-global.website-files.com/6352c70fd33e6a79421e54ef/659e5fd3dd4c8db85e099be8_quote.svg"
                    width="50"
                    height="41"
                    alt=""
                  />
                  <Typography variant="h3" className={classes.title}>
                    {testimonial.quote}
                  </Typography>
                  <Typography
                    variant="overline"
                    variantMapping={{ overline: 'p' }}
                  >
                    {testimonial.author}
                    <br />
                    {testimonial.title}
                  </Typography>
                </div>
              ))}
            </Slider>
          </Box>
        </Container>
      </Box>
    </BackgroundImage>
  )
}

const StyledTestimonials = styled(Testimonials)`
  background-position: left;
`
export default StyledTestimonials
