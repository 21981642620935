import React, { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'

const withSessionReplay = Page => props => {
  useEffect(() => {
    datadogRum.init({
      applicationId: '7b57a194-0061-423a-ae5c-38e43110f715',
      clientToken: 'pub46975f92a89f69b70009664c43a602b9',
      site: 'datadoghq.com',
      service: 'pga.coach',
      env: 'production',
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    })
    datadogRum.startSessionReplayRecording()

    return () => {}
  }, [])

  return <Page {...props} />
}

export { withSessionReplay }
